import { defineComponent, ref } from "vue";
import logo from "@/components/warmup/media/ga-logo-negative.svg";
import defaultImage from "@/components/warmup/media/getinspired.png";
import { ActionableCardMini } from "@/components/actionable/ActionableCardMini";
import type { ActionableMiniView } from "@generated/model/actionableMiniView";
import type { ActionableListView } from "@generated/model/actionableListView";
import { ActionableCard } from "@/components/actionable/ActionableCard";
import { DateTime } from "@utils/type/type";
import { GaFormFieldInputText } from "@/components/form/GaFormFieldInputText";
import { GaFormFieldInputSelect, type SelectOption } from "@/components/form/GaFormFieldInputSelect";
import { GaFormFieldInputCheckbox } from "@/components/form/GaFormFieldInputCheckbox";
import { GaButton } from "@/components/general/GaButton";
import { SummaryCardMini } from "@/components/cards/SummaryCardMini";
import { SummaryCard } from "@/components/cards/SummaryCard";
import type { GaVueComponent } from "@/common/vueUtils";
import type { SummaryMetadata } from "@newgenerated/shared/schema";
import "@/components/warmup/warmup-v2.scss";

type WarmupV2Options = {
  kind: "default" | "summary" | "actionable";
  showCustomFields: boolean;
  showName: boolean;
  showPassword: boolean;
  prefillEmail: boolean;
};

const actionable: ActionableListView = {
  actionableId: BigInt(1),
  name: "ttt",
  title: "Title of This Learning Experience Card",
  description: "",
  active: true,
  language: "en",
  coverUri: "/actionable-img/82-foster-an-organizational-learning-culture-1700131306000.jpg",
  activatedAt: null,
  modifiedAt: DateTime.now(),
};

const actionableMini: ActionableMiniView = {
  actionableId: BigInt(1),
  title: "Title of This Learning Experience Card",
  name: "ttt",
  coverUri: "/actionable-img/82-foster-an-organizational-learning-culture-1700131306000.jpg",
};

const summary: SummaryMetadata = {
  dataId: 1,
  title: "The 10 Natural Laws of Successful Time and Life Management",
  subtitle: null,
  originalTitle: null,
  teaser: null,
  language: "en",
  sourceType: "BOOK",
  documentType: "ABSTRACT",
  canonicalName: "",
  coverUri: "/summary-img/2-JQS5X25C.jpg",
  rating: 6,
  authors: [],
  authorInfo: "Jogi Rippel und Scott Peltin",
  publisherName: null,
  publicationDate: null,
  publisherInfo: null,
  bestseller: true,
  businessClassic: true,
  active: true,
  activatedAt: null,
  audioActive: true,
  audioActivatedAt: null,
  audioDuration: 1,
  previewAvailable: true,
  modifiedAt: null,
  duration: 1,
  coverModifiedAt: null,
  imageBorder: false,
  countriesExcluded: [],
  countriesIncluded: [],
  summaryPages: 1,
  summaryWords: 1,
  contentItemId: BigInt(1),
  contentItemType: "SUMMARY",
  activationTime: null,
  descriptionHtml: null,
  tags: [],
};

function WarmupV2(props: { opts: WarmupV2Options; page: 0 | 1; nextPage: () => void }): GaVueComponent {
  return (
    <div class="warmupV2">
      <aside class="warmupV2-sidebar">
        <div class="text-center">
          <img src={logo} alt="getAbstract" width="182" height="30" />
        </div>
        <div class="warmupV2-sidebar-image">
          {props.opts.kind === "actionable" ? <ActionableCard actionable={actionable} /> : null}
          {props.opts.kind === "summary" ? (
            <div class="warmupV2-sidebar-image-summary">
              <SummaryCardMini summary={summary} />
            </div>
          ) : null}
          {props.opts.kind === "default" ? <img src={defaultImage} alt="" /> : null}
        </div>
        <span class="warmupV2-sidebar-slogan">
          You’re only <strong>one step</strong> away!
        </span>
      </aside>
      <aside class="warmupV2-sidebar--mobile">
        {props.opts.kind === "actionable" || props.opts.kind === "summary" ? (
          <span>
            You’re only <strong>one step</strong> away!
          </span>
        ) : null}
        {props.opts.kind === "actionable" ? <ActionableCardMini actionable={actionableMini} /> : null}
        {props.opts.kind === "summary" ? <SummaryCard summary={summary} compact={true} /> : null}
      </aside>
      <main class="warmupV2-main">
        <h2>Join your peers from Deutsche Bank on getAbstract!</h2>
        <p>Unlock getAbstract’s 30,000+ learning resources to elevate your career and personal growth.</p>
        {props.page === 0 ? (
          <form action="javascript:void 0;">
            <div class="ga-dynamic-grid" style="--ga-dynamic-grid--min-width: 17rem;">
              {props.opts.showName ? (
                <>
                  <GaFormFieldInputText value={""} onUpdateValue={() => {}} name="first name" label="First Name" />
                  <GaFormFieldInputText value={""} onUpdateValue={() => {}} name="last name" label="Last Name" />
                </>
              ) : null}
              {props.opts.showPassword ? (
                <>
                  <GaFormFieldInputText type="email" value={props.opts.prefillEmail ? "gorgieboy@atlassian.com" : ""} onUpdateValue={() => {}} name="email" label="Email Address" disabled={props.opts.prefillEmail} />
                  <GaFormFieldInputText type="password" value={""} onUpdateValue={() => {}} name="password" label="Password" />
                </>
              ) : null}
              <GaFormFieldInputSelect options={[{ value: 1, label: "English" }]} name="language" value={1} onInputValue={() => {}} label="Language" />
              <GaFormFieldInputSelect options={[{ value: 1, label: "United States" }]} name="country" value={1} onInputValue={() => {}} label="Country" />
              <div class="mt-3">
                <label for="often">
                  <small>How often do you want to receive insights that keep you on top?</small>
                </label>
                <GaFormFieldInputSelect options={[{ value: 1, label: "Weekly (Recommended)" }]} name="often" value={1} onInputValue={() => {}} />
              </div>
            </div>
            <div class="mt-5">
              <GaFormFieldInputCheckbox checked={false} onUpdateChecked={() => {}} name="toc" label="I have read and agree to the Terms and Conditions and to getAbstract’s Privacy Policy." />
            </div>
            {props.opts.showCustomFields ? (
              <div class="mt-4">
                <GaButton type="submit" onClick={props.nextPage}>
                  Next
                </GaButton>
              </div>
            ) : (
              <div class="mt-4">
                <GaButton type="submit" onClick={() => {}}>
                  Start Learning Now
                </GaButton>
              </div>
            )}
          </form>
        ) : (
          <form action="javascript:void 0;">
            <div class="mt-4">
              <div class="mt-5 mb-3 ga-dynamic-grid" style="--ga-dynamic-grid--min-width: 17rem;">
                <GaFormFieldInputText value={""} onUpdateValue={() => {}} name="extraField.extraField1" label="favourite book" />
                <GaFormFieldInputSelect options={[{ value: 1, label: "Weekly" }]} name="extraField.extraField2" value={1} onInputValue={() => {}} label="Number of coffees per day" />
                <GaFormFieldInputSelect options={[{ value: 1, label: "A" }]} name="extraField.extraField3" value={1} onInputValue={() => {}} label="Another mandatory field" />
              </div>
              <div class="mt-5">
                <GaButton type="submit" onClick={() => {}}>
                  Start Learning Now
                </GaButton>
              </div>
            </div>
          </form>
        )}
      </main>
    </div>
  );
}

const kindOptions: SelectOption<"default" | "summary" | "actionable">[] = [
  { value: "default", label: "Default" },
  { value: "summary", label: "Summary" },
  { value: "actionable", label: "Actionable" },
];

const defaultOptions = {
  kind: "default",
  showCustomFields: true,
  showName: true,
  showPassword: true,
  prefillEmail: false,
} satisfies WarmupV2Options;

export const WarmupV2Demo = defineComponent({
  setup() {
    const opts = ref<WarmupV2Options>({ ...defaultOptions });

    const page = ref<0 | 1>(0);

    return () => (
      <div style="min-height: 90vh; display: grid; grid-template-rows: auto 1fr auto;">
        <WarmupV2 opts={opts.value} page={page.value} nextPage={() => (page.value = 1)} />
        <br class="m-5" />
        <div class="card p-2 border-dark" style="max-width: unset;">
          <GaFormFieldInputSelect options={kindOptions} name="toggleKind" value={opts.value.kind} onInputValue={(newValue) => (opts.value.kind = newValue)} label="Toggle type (Default, Summary, Actionable)" />
          <div class="my-3 ga-dynamic-grid" style="--ga-dynamic-grid--min-width: 15rem;">
            <GaFormFieldInputCheckbox checked={opts.value.showCustomFields} onUpdateChecked={(newValue) => (opts.value.showCustomFields = newValue)} name="showCustomFields" label="Show Custom Fields (two steps)" />
            <GaFormFieldInputCheckbox checked={opts.value.showName} onUpdateChecked={(newValue) => (opts.value.showName = newValue)} name="showName" label="Show Name" />
            <GaFormFieldInputCheckbox checked={opts.value.showPassword} onUpdateChecked={(newValue) => (opts.value.showPassword = newValue)} name="showPassword" label="Show Email & Password" />
            <GaFormFieldInputCheckbox checked={opts.value.prefillEmail} onUpdateChecked={(newValue) => (opts.value.prefillEmail = newValue)} name="prefill" label="Prefill Email" />
          </div>
          <GaButton
            variant="primary-alt"
            onClick={() => {
              opts.value = { ...defaultOptions };
              page.value = 0;
            }}>
            Reset
          </GaButton>
          {/* TODO: Button which displays a modal */}
        </div>
      </div>
    );
  },
});
